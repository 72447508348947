:root {
  --bg-color-1:#0F131A;
  --bg-color-2:#11161e;
  --bg-color-3:#131821;
  --color-text:#e5e5e5;
  --color-text-light:#f0f0f0;
  --color-text-dark:#aaa;
  --warna-oren:#F2BB17;
  --box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  --box-shadow-medium: 0 .5rem 1rem rgba(0,0,0,.35);
}

/* CSS Reset */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
*,*:before,*:after{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}
body{line-height:1}
a{color: darkgoldenrod;}
a:hover{color: var(--warna-oren);}
ol,ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}


body {
  background: var(--bg-color-3);
  color: var(--color-text);
  line-height: 165.5%;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

